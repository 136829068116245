import React from 'react';
import Header from './components/Header';
import MainSection from './components/MainSection';
import MyModsSection from './components/MyModsSection';
import CustomModsSection from './components/CustomMods';
import FAQ from './components/FAQ';
import Footer from './components/Footer';

import './i18n';

function App() {
  return (
    <div className='App'>
      <Header/>
      <MainSection/>
      <MyModsSection />
      <CustomModsSection />
      <FAQ />
      <Footer />
    </div>
  );
}

export default App;