import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

const resources = {
  en: {
    translation: {
        "Donate": "Donate",
        "MainSection-h1": "The best mods for your DayZ server.",
        "MainSection-p": "All my mods you can find n my steam workshop page. If you need a specific mod, you can contact me and we can discuss commission work.",
        "MyMods-h2": "My Mods",
        "MyMods-p": "Here are some of the mods I have created for the DayZ community.",
        "CustomMods-h2": "Custom Mods",
        "CustomMods-p": "Here are some custom mods I've created for various projects.",
        "FAQ-q1": "Can I use your mods on my server?",
        "FAQ-a1": "<b>Of course</b>, you can use any mods on your server that are in the workshop.",
        "FAQ-q2": "Can I repack or re-upload your mods?",
        "FAQ-a2": "<b>No</b>, but if the mod's workshop page says it's allowed, then <b>Yes</b>.",
        "FAQ-q3": "Can I buy repack or re-upload permission?",
        "FAQ-a3": "<b>No</b>. Under no circumstances and for no amount of money.",
        "FAQ-q4": "How can I order a custom mod from you?",
        "FAQ-a4": "Contact me via my <b><a href=\"https://discord.gg/Hg9ZFn3Avb\" target=\"_blank\" rel=\"noopener noreferrer\">Discord</a></b>.",
        "FAQ-q5": "Can I use your mods on monetized server?",
        "FAQ-a5": "Yes, but the content from my mods must not be monetized.",
        "FAQ-q6": "I can't attach items to slots, what should I do?",
        "FAQ-a6": "This is a <b>mod conflict</b>. Another mod adds its own attachment slots to this item and it does it incorrectly. The problem is not in my mod, so I can't fix it.",
        "FAQ-q7": "I have a problem with \"your problem\", can you help?",
        "FAQ-a7": "Please read the mod description in the workshop in detail and check the mod's .INFO folder. If you haven't found a solution to your problem, you can contact me via my <b><a href=\"https://discord.gg/Hg9ZFn3Avb\" target=\"_blank\" rel=\"noopener noreferrer\">Discord</a></b>.",
        "FAQ-q8": "Can I order retextures of items in your mod?",
        "FAQ-a8": "<b>No</b>, I don't do retextures.",
        "FAQ-q9": "I have problem with mod's config file, can you help?",
        "FAQ-a9": "All information about the correct filling of the config is in the <b>.INFO</b> folder of the mod. Pay close attention to commas and other special characters. You can also check your <b>.json</b> file for errors <b><a href=\"https://jsonlint.com\" target=\"_blank\" rel=\"noopener noreferrer\">here</a></b>.",
    }
  },
  ru: {
    translation: {
        "Donate": "Пожертвовать",
        "MainSection-h1": "Лучшие моды для вашего сервера DayZ.",
        "MainSection-p": "Все мои моды вы можете найти на моей странице мастерской Steam. Если вам нужен конкретный мод, вы можете связаться со мной, и мы сможем обсудить работу на заказ.",
        "MyMods-h2": "Мои Моды",
        "MyMods-p": "Вот некоторые из модов, которые я создал для сообщества DayZ.",
        "CustomMods-h2": "Пользовательские моды",
        "CustomMods-p": "Вот некоторые моды, которые я создал на заказ для разных проектов.",
        "FAQ-q1": "Могу ли я использовать ваши моды на своем сервере?",
        "FAQ-a1": "<b>Конечно</b>, вы можете использовать на своем сервере любые моды, которые есть в мастерской.",
        "FAQ-q2": "Могу ли я перепаковать или перезалить ваши моды?",
        "FAQ-a2": "<b>Нет</b>, но если на странице мода в мастерской написано, что это разрешено, то <b>Да</b>.",
        "FAQ-q3": "Могу ли я купить разрешение на репак или перезалив?",
        "FAQ-a3": "<b>Нет</b>. Ни при каких обстоятельствах и ни за какие деньги.",
        "FAQ-q4": "Как я могу заказать у вас индивидуальный мод?",
        "FAQ-a4": "Свяжитесь со мной через мой <b><a href=\"https://discord.gg/Hg9ZFn3Avb\" target=\"_blank\" rel=\"noopener noreferrer\">Discord</a></b>.",
        "FAQ-q5": "Могу ли я использовать ваши моды на монетизированном сервере?",
        "FAQ-a5": "Да, но контент из моих модов не должен монетизироваться.",
        "FAQ-q6": "Я не могу прикрепить предметы к слотам, что мне делать?",
        "FAQ-a6": "Это <b>конфликт модов</b>. Другой мод добавляет к этому предмету свои слоты аттачментов и делает это неправильно. Проблема не в моем моде, поэтому я не могу ее исправить.",
        "FAQ-q7": "У меня проблема с \"ваша проблема\", вы можете помочь?",
        "FAQ-a7": "Пожалуйста, подробно прочитайте описание мода в мастерской и проверьте папку .INFO мода. Если вы не нашли решения проблемы, вы можете связаться со мной через мой <b><a href=\"https://discord.gg/Hg9ZFn3Avb\" target=\"_blank\" rel=\"noopener noreferrer\">Discord</a></b>.",
        "FAQ-q8": "Могу ли я заказать ретекстур предметов из вашего мода?",
        "FAQ-a8": "<b>Нет</b>, я не занимаюсь ретекстурированием.",
        "FAQ-q9": "У меня проблема с конфигом мода, вы можете помочь?",
        "FAQ-a9": "Вся информация о правильном заполнении конфига находится в папке <b>.INFO</b> мода. Обратите внимание на запятые и другие специальные символы. Вы также можете проверить свой <b>.json</b> файл на наличие ошибок <b><a href=\"https://jsonlint.com\" target=\"_blank\" rel=\"noopener noreferrer\">тут</a></b>.",
    }
  }
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;