import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../styles/MainSection.css';

function MainSection() {
  const { t } = useTranslation();
  const [showScrollIndicator, setShowScrollIndicator] = useState(true);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) {
                setShowScrollIndicator(false);
            } else {
                setShowScrollIndicator(true);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

  return (
    <div className="main-section">
      <div className="main-info">
        <div className="content">
          <h1>{t('MainSection-h1')}</h1>
          <p>{t('MainSection-p')}</p>
          <button onClick={() => window.open("https://steamcommunity.com/id/Evgeny__N/myworkshopfiles/?appid=221100", "_blank")} className="steambutton" href="https://boosty.to/lowner-evgeny__n" target="_blank" rel="noopener noreferrer"><span>Steam Workshop</span><div className="icon">
		          <i className="fa-brands fa-steam fa-2xl"></i>
          </div></button>
        </div>
        <div className="image">
          <video className='modsVideo' autoPlay loop muted playsinline src="/data/videos/mods.mp4"/>
        </div>
      </div>
      <div className={`scroll-indicator ${!showScrollIndicator ? 'hidden' : ''}`}>
          <i className="fas fa-chevron-down"></i>
      </div>
    </div>
  );
}

export default MainSection;