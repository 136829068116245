import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../styles/Header.css';

function Header() {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header className="header">
      <div className="logo">
        <img src="/data/images/lownerLogo.gif" alt="Lowner Logo" />
      </div>
      <nav className={`nav ${isOpen ? 'open' : ''}`}>
        <a href="https://auth.lowner.dev" target="_blank" rel="noopener noreferrer">Auth</a>
        <a href="https://discord.gg/Hg9ZFn3Avb" target="_blank" rel="noopener noreferrer">Discord</a>
        <a href="https://boosty.to/lowner-evgeny__n" target="_blank" rel="noopener noreferrer">{t('Donate')}</a>
      </nav>
      <div className="menu-icon" onClick={toggleMenu}>
        <div className="menu-bar"></div>
        <div className="menu-bar"></div>
        <div className="menu-bar"></div>
      </div>
    </header>
  );
}

export default Header;
