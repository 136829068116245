import React from 'react';
import { useTranslation } from 'react-i18next';
import '../styles/MyModsSection.css';

function MyModsSection() {
  const { t } = useTranslation();
  const mods = [
    { modUrl: 'https://steamcommunity.com/sharedfiles/filedetails/?id=2988099206', imageUrl: '/data/images/drones.jpg' },
    { modUrl: 'https://boosty.to/dayzbastard/posts/41bdf319-cb44-4e08-9c7b-e820ab1a1273?share=post_link', imageUrl: '/data/images/liveRadio.jpg' },
    { modUrl: 'https://boosty.to/lowner-evgeny__n/posts/fb5a3ace-e499-4efe-b744-82e85e3378f3?share=post_link', imageUrl: '/data/images/homemadeTurrets.jpg' },
    { modUrl: 'https://steamcommunity.com/sharedfiles/filedetails/?id=2851374375', imageUrl: '/data/images/automatedTurrets.jpg' },
    { modUrl: 'https://steamcommunity.com/sharedfiles/filedetails/?id=2818322021', imageUrl: '/data/images/hackingSystem.jpg' },
    { modUrl: 'https://steamcommunity.com/sharedfiles/filedetails/?id=2810215299', imageUrl: '/data/images/staticPlanes.jpg' },
    { modUrl: 'https://steamcommunity.com/sharedfiles/filedetails/?id=2810212624', imageUrl: '/data/images/customKeycards.jpg' },
  ];

  return (
    <section className="my-mods-section">
      <h2>{t('MyMods-h2')}</h2>
      <p>{t('MyMods-p')}</p>
      <div className="mods-grid">
        {mods.map((mod, index) => (
          <div key={index} className="mod-item">
            <a href={mod.modUrl} target="_blank" rel="noopener noreferrer"><img src={mod.imageUrl} alt="modImage"/></a>
          </div>
        ))}
      </div>
    </section>
  );
}

export default MyModsSection;