import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../styles/FAQ.css';

function FAQ() {
  const { t } = useTranslation();
  const [activeIndices, setActiveIndices] = useState([]);

  const faqs = [
    {
      question: t('FAQ-q1'),
      answer: t('FAQ-a1')
    },
    {
      question: t('FAQ-q2'),
      answer: t('FAQ-a2')
    },
    {
      question: t('FAQ-q3'),
      answer: t('FAQ-a3')
    },
    {
      question: t('FAQ-q4'),
      answer: t('FAQ-a4')
    },
    {
      question: t('FAQ-q5'),
      answer: t('FAQ-a5')
    },
    {
      question: t('FAQ-q6'),
      answer: t('FAQ-a6')
    },
    {
      question: t('FAQ-q7'),
      answer: t('FAQ-a7')
    },
    {
      question: t('FAQ-q8'),
      answer: t('FAQ-a8')
    },
    {
      question: t('FAQ-q9'),
      answer: t('FAQ-a9')
    }
  ];

  const toggleFAQ = (index) => {
    setActiveIndices(prevIndices =>
      prevIndices.includes(index)
        ? prevIndices.filter(i => i !== index)
        : [...prevIndices, index]
    );
  };

  return (
    <div className="faq-section">
      <h2>F.A.Q.</h2>
      <div className="faq-container">
        {faqs.map((faq, index) => (
          <div 
            key={index} 
            className={`faq-item ${activeIndices.includes(index) ? 'active' : ''}`}
            onClick={() => toggleFAQ(index)}
          >
            <div className="faq-question">
              <span className="faq-question-text">{faq.question}</span>
              <i className={`fas ${activeIndices.includes(index) ? 'fa-times' : 'fa-plus'}`}></i>
            </div>
            <div className="faq-answer">
                <p dangerouslySetInnerHTML={{ __html: faq.answer }}></p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default FAQ;
